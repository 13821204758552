@import "../../../partials/colors";
@import "../../../partials/mixins";

.background-file-share{
    z-index: 13;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.548);
    align-items: center;
    justify-content: center;
    display: none;
    #fileShareContainer{
        border-radius: 5px;
        z-index: 13;
        width: 600px;
        height: 350px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(53, 67, 93, 0.3);
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0,0,0,0.1);
        transform-style: preserve-3d;
        padding-top: 8%;
        @include appearAnimation;
        #close{
            position: absolute;
            top: 3%;
            right: 3%;
            border: none;
            background: none;
            img{
                width: 20px;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
        #share{
            display: flex;
            flex-direction: row;
            margin-left: 5%;
            margin-top: 5%;
            #shareContainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 200px;
                width: 50%;
                .selectedUser{
                    background-color: $terneryBG;
                    font-size: 14px;
                    height: 30px;
                    width: 230px;
                    padding: 1%;
                    padding-left: 5%;
                    border-radius: 5px;
                    cursor: pointer;
                    color: $primaryTextColor;
                    border: none;
                }
                .usersSelect{
                    border-radius: 5px;
                    width: 0px;
                    overflow-x: hidden;
                    height: 0px;
                    overflow-y: scroll;
                    margin-top: 1%;
                    transition: 0.2s height;
                    cursor: pointer;
                    max-height: 140px;
                    .singleUser{
                        font-size: 12px;
                        height: 40px;
                        width: 250px;
                        padding: 2%;
                        padding-left: 8%;
                        background-color: $primaryBGLight;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .singleUser:hover{
                        background-color: $terneryBG;
                    }
                }
                .usersSelect.active{
                    width: fit-content;
                    height: fit-content;
                }
            }
        }
        #shareInfo{
            font-size: 12px;
        }
        #uploadButton{
            width: 95%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            button{
                @include button-green;
            }
            button:hover{
                @include button-green-hover;
            }
        }
    }
}
.background-file-share.active {
    display: flex;
}

@media screen and (max-width: 700px){
    .background-file-share{
        #fileShareContainer{
            width: 280px;
            height: 380px;
            #close{
                img{
                    width: 20px;
                }
            }
            #share{
                flex-direction: column;
                #shareContainer{
                    width: 100%;
                }
            }
            #shareInfo{
                font-size: 10px;
                height: 80px;
            }
            #uploadButton{
                button{
                    width: 50%;
                }
            }
        }
    }
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    /* font-size: 100%; */
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background-color: #1b2c42;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(57, 81, 122);
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #1567d3;
    box-shadow: 0 4px 8px 0 rgba(0, 132, 255, 0.2), 0 6px 20px 0 rgba(0, 98, 179, 0.19);
    border-radius: 5px;
}
@import "../../../partials/colors";
@import "../../../partials/mixins";

#fileSection{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $primaryTextColor;
    position: relative;
    @include appearAnimation;
    #button{
        height: 80px;
        border-radius: 5px;
        button{
            @include button-blue;
            float: right;
        }
        button:hover{
            @include button-blue-hover;
        }
    }
    #files{
        border: 1px solid $primaryBlue;
        border-radius: 5px;
        height: 90%;
        padding: 2%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 700px){
    #fileSection{
        #button{
            height: 60px;
        }
    }
}
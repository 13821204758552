@import "../../../partials/colors";
@import "../../../partials/mixins";

.background-file{
    z-index: 13;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    #fileUploadContainer{
        border-radius: 5px;
        z-index: 13;
        width: 600px;
        height: 360px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(53, 67, 93, 0.3);
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0,0,0,0.1);
        padding-top: 8%;
        overflow: hidden;
        @include appearAnimation;
        #close{
            position: absolute;
            top: 3%;
            right: 3%;
            border: none;
            background: none;
            img{
                width: 20px;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            #fileSelectContainer{
                width: 95%;
                height: 135px;
                #fileSelect{
                    margin-top: 1%;
                    width: 100%;
                    height: 100px;
                    border-radius: 5px;
                    background-color: $terneryBG;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    label{
                        @include button-blue;
                        img{
                            width: 15px;
                            margin-right: 5%;
                        }
                    }
                    label:hover{
                        @include button-blue-hover;
                    }
                    #fileInput {
                        display: none;
                        overflow: hidden;
                    }
                    #filename{
                        visibility: hidden;
                    }
                }
            }
            #addDescriptionContainer{
                width: 95%;
                height: 135px;
                #addDescriptionTxt{
                    margin-top: 1%;
                    background-color: $terneryBG;
                    color: $primaryTextColor;
                    font-size: 15px;
                    border: none;
                    width: 98.5%;
                    border-radius: 5px;
                    padding-left: 10px;
                    resize: none;
                    height: 100px;
                    padding-top: 10px;
                }
            }
            #uploadButton{
                width: 95%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                .uploading{
                    width: 22%;
                    display: none;
                }
                .progress{
                    display: none;
                }
                button{
                    @include button-green;
                }
                button:hover{
                    @include button-green-hover;
                }
                @keyframes load {
                    0%{
                        transform: rotate(0deg);;
                    }
                    100%{
                        transform: rotate(360deg);
                    }
                }
                .uploading.active{
                    display: inline-block;
                }
                .progress.active{
                    display: inline-block;
                }
            }
            .progressContainer{
                width: 100%;
                height: 6px;
                position: absolute;
                bottom: 0;
                display: none;
                .progressBar{
                    height: 8px;
                    background-color: green;
                    width: 0%; 
                }
            }
            .progressContainer.active{
                display: block;
            }
        }
    }
}
.background-file.active{
    display: flex;
}

@media screen and (max-width: 700px){
    .background-file{
        #fileUploadContainer{
            width: 280px;
            height: 350px;
            #close{
                img{
                    width: 20px;
                }
            }
            form{
                #fileSelectContainer{
                    height: 130px;
                    #fileSelect{
                        span{
                            font-size: 10px;
                        }
                        label{
                            img{
                                width: 12px;
                            }
                        }
                    }
                }
                #addDescriptionContainer{
                    height: 140px;
                    #addDescriptionTxt{
                        width: 96.5%;
                    }
                }
                #uploadButton{
                    .uploading{
                        width: 50%;
                    }
                    .loader{
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}
@import "../../partials/colors";

#navContainer{
    background-color: $primaryBG;
    width: 100%;
    height: 220px;
    position: relative;
    z-index: 10;
    #logo{
        position: absolute;
        left: 5%;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        button{
            border: none;
            background: none;
            b{
                font-size: 25px;
                color: $primaryTextColor;
                // font-family: 'Julius Sans One', sans-serif;
            }
            img{
                width: 75px;
                height: 75px;
            }
        }
    }
    #navItems{
        list-style: none;
        float: right;
        position:absolute;
        bottom: 55%;
        right: 30%;
        li{
            display: inline-block;
            padding-inline: 30px;
            a{
                text-decoration: none;
                color: $primaryTextColor;
                align-items: center;
                font-size: 15px;
                font-weight: 500;
                padding-bottom: 5px;
            }
            a:hover{
                border-bottom: 3px solid $primaryBlue;
            }
        }
    }
    #loginContainer{
        height: 150px;
        width: 80px;
        position: absolute;
        right: 5%;
        top: 5%;
        font-size: 15px;
        #login{
            position: absolute;
            right: 4%;
            top: 5%;
            display: flex;
            flex-direction: row;
            align-items: center;
            #dropdown{
                position: relative;
                display: inline-block;
                button{
                    border: none;
                    background: none;
                    #bellIcon{
                        width: 25px;
                        height: 25px;
                        margin-inline: 25px;
                        cursor: pointer;
                    }
                    #userIcon{
                        border: 2px solid white;
                        border-radius: 50%;
                        padding: 5px;
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                    }
                    #loginIcon{
                        padding: 5px;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                    }
                }
                #dropdownContent{
                    display: none;
                    position: absolute;
                    top: 100%;
                    background-color: $primaryBGLight;
                    width: 120px;
                    height: 80px;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    z-index: 20;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-top: 10%;
                    border-radius: 5px;
                    overflow: hidden;
                    div{
                        height: 40px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            text-decoration: none;
                            display: block;
                            color: $primaryTextColor;
                            cursor: pointer;
                        }
                    }
                    div:hover{
                        background-color: $terneryBG;
                    }
                }
            }
            #userIconButton:hover #dropdownContent {display: flex;}
        }
    }
}

@media screen and (max-width: 700px){
    #navContainer{
        height: 300px;
        #navItems{
            height: fit-content;
            width: 100%;
            position: absolute;
            top: 20%;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            li{
                display: block;
                width: 100%;
                padding-inline: 0px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                a{
                    font-size: 15px;
                }
                a:hover{
                    border: none;
                }
            }
            li:hover{
                background-color: #41464d;
            }
        }
        #loginContainer{
            #login{
                right: 15%;
                top: 5%;
                #dropdown{
                    right: 10%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
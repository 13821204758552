@import "../../../partials/colors";
@import "../../../partials/mixins";

#textSection{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $primaryTextColor;
    @include appearAnimation;
    #button{
        height: 80px;
        border-radius: 5px;
        button{
            @include button-blue;
            float: right;
        }
    }
    #texts{
        border: 1px solid $primaryBlue;
        border-radius: 5px;
        height: 90%;
        padding: 2%;
        display: block;
        justify-content: center;
        flex-wrap: wrap;
        overflow: scroll;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 700px){
    #textSection{
        #button{
            height: 60px;
        }
    }
}
@import "../../partials/colors";
@import "../../partials/mixins";

#home1{
    height: 600px;
    color: $primaryTextColor;
    background-color: $secondaryBG;
    position: relative;
    overflow: hidden;
    z-index: 10;
    #text{
        position: absolute;
        left: 10%;
        top: 15%;
        width: 20%;
        @include appearAnimation;
        h1{
            font-size: 50px;
        }
        p{
            font-size: 20px;
        }
        button{
            @include button-blue;
            margin-inline-end: 5px;
            @include appearAnimation;
            display: inline;
        }
        button:hover{
            @include button-blue-hover;
        }
        #howToUse{
            width: 120px;
            color: $primaryBlue;
            background: none;
            border: 2px solid $primaryBlue;
            @include appearAnimation;
        }
    }
    #img{
        position: absolute;
        right: 8%;
        top: 0%;
        @include appearAnimation;
        img{
            width: 400px;
        }
    }
}
#home1::before{
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    width: 350%;
    background-color: $primaryBG;
    border-radius: 100%;
    left: 50%;
    bottom: -120%;
    top: -30%;
}

#submitYourQueries{
    color: $primaryTextColor;
    background-color: $secondaryBG;
    height: 600px;
    position: relative;
    font-size: 15px;
    #submitYourQueriesText{
        width: 35%;
    }
    form{
        margin-top: 3%;
        position: relative;
        width: 30%;
        height: 340px;
        display: flex;
        flex-direction: column;
        padding-left: 5%;
        .data{
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            .txt{
                background-color: $textBoxBG;
                color: $primaryTextColor;
                font-size: 15px;
                border: none;
                width: 50%;
                border-radius: 5px;
                position: absolute;
                left: 40%;
                padding-left: 10px;
            }
            .txt:hover{
                border: 2px solid $primaryBlue;
            }
            input{
                height: 35px;
            }
            textarea{
                resize: none;
                height: 135px;
                padding-top: 10px;
            }
            button{
                @include button-green;
                width: 120px;
                height: 40px;
                font-size: 14px;
                position: absolute;
                left: 40%;
                top: 80%;
                img{
                    width: 16px;
                }
            }
            button:hover{
                @include button-green-hover;
            }
        }
    }
}

#aboutSaveit {
    background-color: $primaryBG;
    height: 400px;
    padding-top: 3%;
    color: $primaryTextColor;
    font-size: 15px;
    #aboutSaveitText{
        width: 32%;
    }
    p{
        line-height: 20px;
    }
    .fa {
        font-size: 25px;
        width: 20px;
        height: 20px;
        text-decoration: none;
        border-radius: 50%;
        margin-inline: 1.7%;
    }
    .fa-instagram{
        color:#ffffff;
    }
    .fa-linkedin{
        color:#ffffff;
    }
    .fa-github{
        color:#ffffff;
    }
}

@media screen and (max-width: 700px){
    #home1{
        height: 600px;
        #text{
            left: 10%;
            top: 15%;
            width: 90%;
            h1{
                font-size: 35px;
            }
            p{
                font-size: 18px;
            }
            button{
                margin-top: -10px;
                width: 120px;
                height: 40px;
            }
            #howToUse{
                width: 100px;
            }
        }
        #img{
            left: 0%;
            top: 35%;
            @include appearAnimation;
            img{
                width: 250px;
            }
        }
    }
    #home1::before{
        width: 550%;
    }
    
    #submitYourQueries{
        height: 550px;
        font-size: 12px;
        #submitYourQueriesText{
            width: 90%;
        }
        form{
            padding-left: 2%;
            margin-top: 10%;
            width: 95%;
            .data{
                .txt{
                    width: 65%;
                    left: 30%;
                }
                button{
                    left: 30%;
                }
                button:hover{
                    @include button-green-hover;
                }
            }
        }
    }
    #aboutSaveit {
        height: 400px;
        padding-top: 10%;
        font-size: 14px;
        #aboutSaveitText{
            width: 90%;
        }
        .fa {
            margin-inline: 6%;
        }
    }
}
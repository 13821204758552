@import "../../partials/colors";
@import "../../partials/mixins";

.background-register{
    z-index: 13;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    #registerDialogContainer{
        color: $primaryTextColor;
        border-radius: 5px;
        width: 630px;
        height: 400px;
        //border: 1px solid rgba(255, 255, 255, 0.3);
        background: url("../../Images/Backgrounds/1.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0,0,0,0.1);
        transform-style: preserve-3d;
        padding-top: 0%;
        position: relative;
        font-size: 14px;
        @include appearAnimation;
        #close{
            position: absolute;
            top: 3%;
            right: 3%;
            border: none;
            background: none;
            img{
                width: 20px;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
        #rightSide{
            height: 100%;
            background-color: rgba(25, 31, 41, 0.644);
            width: 50%;
            float: right;
            overflow: hidden;
            form{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 15%;
                margin-left: 1%;
                .credentialsInput{
                    margin-top: 1%;
                    width: 95%;
                    height: 30px;
                    border-radius: 5px;
                    background-color: rgba(25, 31, 41, 0.671);
                    border: none;
                    color: $primaryTextColor;
                    font-size: 14px;
                    padding-left: 10px;
                }
                .credentialsInput:hover{
                    border: 1px solid $linkTextColor;
                }
                #emailContainer, #nameContainer, #usernameContainer, #passwordContainer{
                    width: 80%;
                    height: 60px;
                }
                #registerButton{
                    width: 95%;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    button{
                        @include button-blue;
                        width: 130px;
                    }
                    button:hover{
                        @include button-blue-hover;
                    }
                }
                #signUpLink{
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 80%;
                    a{
                        color: $linkTextColor;
                        font-weight: 900;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.background-register.active{
    display: flex;
}

@media screen and (max-width: 700px){
    .background-register{
        #registerDialogContainer{
            width: 280px;
            height: 400px;
            #close{
                img{
                    width: 20px;
                }
            }
            #rightSide{
                width: 100%;
                form{
                    #registerButton{
                        button{
                            @include button-blue;
                            width: 120px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
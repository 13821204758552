@import "../../../partials/colors";
@import "../../../partials/mixins";

.background-text{
    z-index: 13;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    #textUploadContainer{
        border-radius: 5px;
        z-index: 13;
        width: 600px;
        height: 350px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(53, 67, 93, 0.3);
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0,0,0,0.1);
        transform-style: preserve-3d;
        padding-top: 8%;
        @include appearAnimation;
        #close{
            position: absolute;
            top: 3%;
            right: 3%;
            border: none;
            background: none;
            img{
                width: 20px;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            #textTitleContainer{
                width: 95%;
                height: 60px;
                #title{
                    margin-top: 1%;
                    width: 98.5%;
                    height: 35px;
                    border-radius: 5px;
                    background-color: $terneryBG;
                    border: none;
                    color: $primaryTextColor;
                    font-size: 15px;
                    padding-left: 10px;
                }
            }
            #addContentContainer{
                width: 95%;
                height: 200px;
                #addContentTxt{
                    margin-top: 1%;
                    background-color: $terneryBG;
                    color: $primaryTextColor;
                    font-size: 14px;
                    border: none;
                    width: 98.5%;
                    border-radius: 5px;
                    padding-left: 10px;
                    resize: none;
                    height: 170px;
                    padding-top: 10px;
                }
            }
            #uploadButton{
                width: 95%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                button{
                    @include button-green;
                }
                button:hover{
                    @include button-green-hover;
                }
            }
        }
    }
}
.background-text.active {
    display: flex;
}

@media screen and (max-width: 700px){
    .background-text{
        #textUploadContainer{
            width: 280px;
            height: 350px;
            #close{
                img{
                    width: 20px;
                }
            }
            form{
                #textTitleContainer{
                    width: 95%;
                    height: 60px;
                    #title{
                        width: 95%;
                        height: 35px;
                        font-size: 14px;
                    }
                }
                #addContentContainer{
                    width: 95%;
                    height: 200px;
                    #addContentTxt{
                        font-size: 12px;
                        width: 95%;
                        height: 170px;
                    }
                }
                #uploadButton{
                    button{
                        width: 50%;
                    }
                }
            }
        }
    }
}


@mixin button-green  {
    height: 40px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    width: 120px;
    background-color: $primaryGreen;
    color: $primaryTextColor;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 18px;
        margin-right: 5px;
    }
}

@mixin button-green-hover{
    box-shadow: 0 4px 8px 0 rgba(0, 121, 64, 0.2), 0 6px 20px 0 rgba(74, 255, 3, 0.19);
}


@mixin button-blue{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 45px;
    background-color: $primaryBlue;
    border: none;
    color: $primaryTextColor;
    font-size: 15px;
    border-radius: 5px;
    margin: 1%;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-decoration: none;
    img{
        width: 18px;
        margin-right: 5px;
    }
}

@mixin button-blue-hover{
    box-shadow: 0 4px 8px 0 rgba(48, 172, 255, 0.2), 0 6px 20px 0 rgba(0, 71, 177, 0.19);
}

@mixin appearAnimation{
    animation: appear 0.6s linear;
    @keyframes appear {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
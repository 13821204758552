@import "../../../partials/colors";

.post{
    border: 1px solid white;
    width: 210px;
    height: 280px;
    padding: 2%;
    font-size: 15px;
    display: inline-block;
    margin-inline: 1%;
    margin-block: 1%;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    transition: 0.2s transform;
    position: relative;
    #sharedIcon{
        width: 20px;
        height: 20px;
        position: absolute;
        right: 7%;
        z-index: 12;
        top: 5%;
    }
    h1{
        overflow: hidden;
        position: relative;
        height: 12%;
    }
    h1::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: linear-gradient(150deg, rgba(255,255,255,0) 0%, rgb(32, 42, 58, 1) 100%);
    }
    p{
        overflow-y: hidden;
        word-wrap: break-word;
        position: relative;
        height: 62%;
    }
    p::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(32, 42, 58, 1) 100%);
    }
    .actions{
        margin-top: 0%;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button{
            width: 20px;
            height: 20px;
            background: none;
            cursor: pointer;
            border: none;
            margin-inline: 25px;
        }
    }
    .actions.hover{
        bottom: 0%;
    }
}
.post:hover{
    border: 1px solid $primaryBlue;
    box-shadow: 0 4px 8px 0 rgba(1, 103, 255, 0.2), 0 6px 20px 0 rgba(0, 109, 199, 0.19);
    transform: scale(1.025);
}

@media screen and (max-width: 700px){
    .post{
        width: 90%;
        height: 250px;
        padding: 6%;
        font-size: 10px;
        #sharedIcon{
            width: 15px;
            height: 15px;
        }
        .actions{
            button{
                width: 15px;
                height: 15px;
                margin-inline: 20px;
            }
        }
    }
}
@import "../../partials/colors";
@import "../../partials/mixins";

#sharingSection{
    height: 2000px;
    width: 100%;
    background-color: $primaryBG;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $primaryTextColor;
    font-size: 15px;
    padding-top: 3%;
    #sharingChoice{
        width: 220px;
        height: fit-content;
        border-radius: 5px;
        margin-right: 1%;
        button{
            color: $primaryTextColor;
            font-size: 15px;
            border: none;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 45px;
            width: 100%;
        }
    }
    #sharingContainer{
        height: 1800px;
        background-color: $primaryBGLight;
        width: 80%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        #content{
            width: 98%;
            height: 95%;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 700px){
    #sharingSection{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #sharingChoice{
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 8%;
        }
        #sharingContainer{
            width: 90%;
            #content{
                height: 98%;
            }
        }
    }
}
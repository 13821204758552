$primaryTextColor: #ffffff;
$linkTextColor: #0054c2;

$primaryBG: rgb(25, 31, 41);
$primaryBGLight: rgb(32, 42, 58);
$secondaryBG: #272d35;
$terneryBGDark: #1c304b;
$terneryBG: #293d58;
$terneryBGLight: #3f597a;

$textBoxBG: #39414b;


$primaryBlue: #006efd;
$primaryGreen: #00972d;
@import "../../partials/colors";

#footer {
    background-color: $secondaryBG;
    height: 150px;
    padding-top: 50px;
    position: relative;
    color: $primaryTextColor;
    #links{
        width: 100px;
        margin-left: 5%;
        a{
            text-decoration: none;
            color: $primaryTextColor;
            display: block;
            font-size: 12px;
            padding-bottom: 10px;
        }
    }
    #copyright {
        width: 100%;
        color: white;
        font-size: 14px;
        p{
            margin-block-end: 5px;
        }
    }
}


@media screen and (max-width: 480px){
    #footer {
        padding-top: 30px;
        a{
            padding-left: 10%;
        }
        #copyright {
            bottom: -10%;
        }
    }
}
@import "../../../partials/colors";

.file{
    height: 60px;
    width: 100%;
    border-radius: 5px;
    display: block;
    border-bottom: 1px solid #ffffff;
    padding-left: 1%;
    overflow: hidden;
    transition: 0.5s height;
    .visible{
        width: 99%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        cursor: pointer;
        #filename{
            width: 28%;
            overflow: hidden;
            position: relative;
        }
        #filename::after{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 11;
            background: linear-gradient(150deg, rgba(255,255,255,0) 0%, rgb(32, 42, 58, 0.8) 100%);
        }
        #shared{
            width: 24%;
            img{
                width: 20px;
                margin-left: 25%;
            }
        }
        #dateUploaded{
            width: 25%;
        }
        #size{
            width: 20%;
        }
        button{
            border: none;
            background: none;
            img{
                width: 20px;
                cursor: pointer;
            }
        }
    }
    .invisible{
        width: 100%;
        height: 80%;
        display: none;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        font-size: 15px;
        #data{
            width: 30%;
            padding-left: 2%;
            margin-top: 1%;
            p{
                margin-block: 2%;
            }
            #filename2{
                width: 90%;
                p{
                    width: 100%;
                    overflow: hidden;
                    word-wrap: break-word;
                }
            }
        }
        #description{
            width: 35%;
            padding-left: 2%;
            margin-right: 5%;
            p{
                margin-block: 5%;
                overflow: hidden;
                word-wrap: break-word;
            }
        }
        #options{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 5%;
            p{
                font-size: 15px;
            }
            .actions{
                height: 70px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                button{
                    width: 35px;
                    height: 35px;
                    background: none;
                    cursor: pointer;
                    border: none;
                    margin-inline: 20px;
                    img{
                        width: 20px;
                    }
                }
            }
        }
    }
}
.file:hover{
    border: 1px solid $primaryBlue;
    box-shadow: 0 4px 8px 0 rgba(1, 103, 255, 0.2), 0 6px 20px 0 rgba(0, 109, 199, 0.19);
}

@media screen and (max-width: 700px){
    .file{
        .visible{
            font-size: 10px;
            #filename{
                width: 28%;
                overflow: hidden;
                position: relative;
            }
            #shared{
                img{
                    width: 15px;
                }
            }
            button{
                img{
                    width: 15px;
                }
            }
        }
        .invisible{
            flex-direction: column;
            font-size: 10px;
            #data{
                width: 100%;
                #filename2{
                    width: 100%;
                    height: 30px;
                    overflow: hidden;
                    position: relative;
                }
                #filename2::after{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                    background: linear-gradient(150deg, rgba(255,255,255,0) 0%, rgb(32, 42, 58, 1) 100%);
                }
            }
            #description{
                width: 100%;
                height: 50px;
                overflow: hidden;
                position: relative;
                p{
                    margin-block: 0%;
                }
            }
            #description::after{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 11;
                background: linear-gradient(150deg, rgba(255,255,255,0) 0%, rgb(32, 42, 58, 0.8) 100%);
            }
            #options{
                margin-top: 0;
                padding-top: 0%;
                height: 60px;
                margin-top: 0%;
                transform: translate(-50%, 0%);
                position: absolute;
                left: 50%;
                bottom: 4%;
                p{
                    font-size: 10px;
                }
                .actions{
                    height: 20px;
                    button{
                        width: 20px;
                        height: 20px;
                        img{
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
}